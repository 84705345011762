export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Nombre y apellidos*',
      requiredErrorMsg: 'Nombre completo obligatorio'
    },
    lastName: {
      name: 'lastName',
      label: 'Apellido*',
      requiredErrorMsg: 'Apellido obligatorio'
    },
    address1: {
      name: 'address2',
      label: 'Dirección*',
      requiredErrorMsg: 'La dirección es obligatoria'
    },

    city: {
      name: 'city',
      label: 'Ciudad*',
      requiredErrorMsg: 'La ciudad es imprescindible'
    },
    state: {
      name: 'state',
      label: 'Estado*',
      requiredErrorMsg: 'La ciudad es imprescindible'
    },
    zipcode: {
      name: 'zipcode',
      label: 'CPF/CNPJ*',
      requiredErrorMsg: 'El código postal es obligatorio',
      invalidErrorMsg: 'Formato de código postal no válido'
    },
    country: {
      name: 'country',
      label: 'País*',
      requiredErrorMsg: 'El país es obligatorio'
    },
    useAddressForPaymentDetails: {
      name: 'useAddressForPaymentDetails',
      label: 'Utilice esta dirección para los datos de pago'
    },
    nameOnCard: {
      name: 'nameOnCard',
      label: 'Nombre en la tarjeta*',
      requiredErrorMsg: 'El nombre en la tarjeta es obligatorio'
    },
    cardNumber: {
      name: 'cardNumber',
      label: 'Número de tarjeta*',
      requiredErrorMsg: 'Se requiere el número de tarjeta',
      invalidErrorMsg: 'El número de tarjeta no es válido (por ejemplo, 1234567891234)'
    },
    expiryDate: {
      name: 'expiryDate',
      label: 'Fecha de caducidad*',
      requiredErrorMsg: 'Fecha de caducidad obligatoria',
      invalidErrorMsg: 'La fecha de caducidad no es válida'
    },
    cvv: {
      name: 'cvv',
      label: 'CVV*',
      requiredErrorMsg: 'CVV obligatorio',
      invalidErrorMsg: 'El CVV no es válido (por ejemplo, 357)'
    }
  }
};
