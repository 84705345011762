import React, {
    useState,
    useEffect,
    useReducer,
    useContext,
    useRef,
} from "react";
// import { SocketContext } from "../../context/Socket/SocketContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core//Grid";
import { Facebook, Instagram, WhatsApp } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import BlockIcon from "@material-ui/icons/Block";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import NewTicketModal from "../../components/NewTicketModal";
import { TagsFilter } from "../../components/TagsFilter";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import formatSerializedId from '../../utils/formatSerializedId';
import { v4 as uuidv4 } from "uuid";

import {
    ArrowDropDown,
    Backup,
    ContactPhone,
} from "@material-ui/icons";
import { Menu, MenuItem } from "@material-ui/core";

import ContactImportWpModal from "../../components/ContactImportWpModal";
import useCompanySettings from "../../hooks/useSettings/companySettings";
import { TicketsContext } from "../../context/Tickets/TicketsContext";

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const Contacts = () => {
    const classes = useStyles();
    const history = useHistory();

    //   const socketManager = useContext(SocketContext);
    const { user, socket } = useContext(AuthContext);


    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Número de filas por página
    const [searchParam, setSearchParam] = useState("");
    const [count, setCount] = useState(0);
    const [contacts, dispatch] = useReducer(reducer, []);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [contactModalOpen, setContactModalOpen] = useState(false);

    const [importContactModalOpen, setImportContactModalOpen] = useState(false);
    const [deletingContact, setDeletingContact] = useState(null);
    const [ImportContacts, setImportContacts] = useState(null);
    const [blockingContact, setBlockingContact] = useState(null);
    const [unBlockingContact, setUnBlockingContact] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [exportContact, setExportContact] = useState(false);
    const [confirmChatsOpen, setConfirmChatsOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [contactTicket, setContactTicket] = useState({});
    const fileUploadRef = useRef(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const { setCurrentTicket } = useContext(TicketsContext);


    const { getAll: getAllSettings } = useCompanySettings();
    const [hideNum, setHideNum] = useState(false);
    const [enableLGPD, setEnableLGPD] = useState(false);
    useEffect(() => {

        async function fetchData() {

            const settingList = await getAllSettings(user.companyId);

            for (const [key, value] of Object.entries(settingList)) {

                if (key === "enableLGPD") setEnableLGPD(value === "enabled");
                if (key === "lgpdHideNumber") setHideNum(value === "enabled");

            }

            // if (settingHideNumber.lgpdHideNumber === "enabled") {
            //     setHideNum(true);
            // }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleImportExcel = async () => {
        try {
            const formData = new FormData();
            formData.append("file", fileUploadRef.current.files[0]);
            await api.request({
                url: `/contacts/upload`,
                method: "POST",
                data: formData,
            });
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(0);
    }, [searchParam, selectedTags]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/contacts/", {
                        params: { searchParam, pageNumber: pageNumber + 1, rowsPerPage, contactTag: JSON.stringify(selectedTags) },
                    });
                    console.log('contacts', data, data.count)
                    setCount(data.count)
                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, rowsPerPage, selectedTags]);

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageNumber(0);
    };

    useEffect(() => {
        const companyId = user.companyId;
        //    const socket = socketManager.GetSocket();

        const onContactEvent = (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
            }
        };
        socket.on(`company-${companyId}-contact`, onContactEvent);

        return () => {
            socket.off(`company-${companyId}-contact`, onContactEvent);
        };
    }, [socket]);

    const handleSelectTicket = (ticket) => {
        const code = uuidv4();
        const { id, uuid } = ticket;
        setCurrentTicket({ id, uuid, code });
    }

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            handleSelectTicket(ticket);
            history.push(`/tickets/${ticket.uuid}`);
        }
    };

    const handleSelectedTags = (selecteds) => {
        const tags = selecteds.map((t) => t.id);
        setSelectedTags(tags);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(true);
    };

    const handleCloseContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(false);
    };

    const hadleEditContact = (contactId) => {
        setSelectedContactId(contactId);
        setContactModalOpen(true);
    };

    const handleDeleteContact = async (contactId) => {
        try {
            await api.delete(`/contacts/${contactId}`);
            toast.success(i18n.t("contacts.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam("");
        setPageNumber(1);
    };

    // Función de ordenamiento estable
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // Función de comparador
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) return -1;
        if (b[orderBy] > a[orderBy]) return 1;
        return 0;
    }

    const handleBlockContact = async (contactId) => {
        try {
            await api.put(`/contacts/block/${contactId}`, { active: false });
            toast.success("Contacto bloqueado");
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam("");
        setPageNumber(1);
        setBlockingContact(null);
    };

    const handleUnBlockContact = async (contactId) => {
        try {
            await api.put(`/contacts/block/${contactId}`, { active: true });
            toast.success("Contacto desbloqueado");
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam("");
        setPageNumber(1);
        setUnBlockingContact(null);
    };

    const handleimportContact = async () => {
        try {
            await api.post("/contacts/import");
            history.go(0);
            setImportContacts(false);
        } catch (err) {
            toastError(err);
            setImportContacts(false);
        }
    };

    const handleimportChats = async () => {
        try {
            await api.post("/contacts/import/chats");
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    // function getDateLastMessage(contact) {
    //     if (!contact) return null;
    //     if (!contact.tickets) return null;

    //     if (contact.tickets.length > 0) {
    //         const date = new Date(contact.tickets[contact.tickets.length - 1].updatedAt);

    //         const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    //         const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    //         const year = date.getFullYear();
    //         const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    //         const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;

    //         return `${day}/${month}/${year} ${hours}:${minutes}`;
    //     }

    //     return null;
    // }


    return (

        <MainContainer className={classes.mainContainer}>


            <Grid container>

                <NewTicketModal
                    modalOpen={newTicketModalOpen}
                    initialContact={contactTicket}
                    onClose={(ticket) => {
                        handleCloseOrOpenTicket(ticket);
                    }}
                />

                <ContactModal
                    open={contactModalOpen}
                    onClose={handleCloseContactModal}
                    aria-labelledby="form-dialog-title"
                    contactId={selectedContactId}
                >

                </ContactModal>

                <ConfirmationModal
                    title={
                        deletingContact
                            ? `${i18n.t(
                                "contacts.confirmationModal.deleteTitle"
                            )} ${deletingContact.name}?`
                            : blockingContact
                                ? `Bloquear Contato ${blockingContact.name}?`
                                : unBlockingContact
                                    ? `Desbloquear Contato ${unBlockingContact.name}?`
                                    : ImportContacts
                                        ? `${i18n.t("contacts.confirmationModal.importTitlte")}`
                                        : `${i18n.t("contactListItems.confirmationModal.importTitlte")}`
                    }
                    open={confirmOpen}
                    onClose={setConfirmOpen}
                    onConfirm={(e) =>
                        deletingContact
                            ? handleDeleteContact(deletingContact.id)
                            : blockingContact
                                ? handleBlockContact(blockingContact.id)
                                : unBlockingContact
                                    ? handleUnBlockContact(unBlockingContact.id)
                                    : ImportContacts
                                        ? handleimportContact()
                                        : handleImportExcel()
                    }
                >
                    {exportContact
                        ?
                        `${i18n.t("contacts.confirmationModal.exportContact")}`
                        : deletingContact
                            ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
                            : blockingContact
                                ? `${i18n.t("contacts.confirmationModal.blockContact")}`
                                : unBlockingContact
                                    ? `${i18n.t("contacts.confirmationModal.unblockContact")}`
                                    : ImportContacts
                                        ? `${i18n.t("contacts.confirmationModal.importMessage")}`
                                        : `${i18n.t(
                                            "contactListItems.confirmationModal.importMessage"
                                        )}`}
                </ConfirmationModal>


                <ConfirmationModal
                    title={i18n.t("contacts.confirmationModal.importChat")}
                    open={confirmChatsOpen}
                    onClose={setConfirmChatsOpen}
                    onConfirm={(e) => handleimportChats()}
                >
                    {i18n.t("contacts.confirmationModal.wantImport")}
                </ConfirmationModal>



            </Grid>




            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <Title>{i18n.t("contacts.title")} ({count})</Title>
                </Grid>


                <Grid item xs={12}>
                    <TagsFilter onFiltered={handleSelectedTags} />
                </Grid>


                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        placeholder={i18n.t("contacts.searchPlaceholder")}
                        type="search"
                        fullWidth
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="secondary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>


                <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 20 }}>
                    <Grid container spacing={3} alignItems="end">
                        

                        <Grid item>

                            <PopupState variant="popover" popupId="demo-popup-menu">
                                {(popupState) => (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 6, paddingLeft: 40, paddingRight: 40, color: "white", fontWeight: "bold" }}
                                            {...bindTrigger(popupState)}
                                        >
                                            Importar / Exportar
                                            <ArrowDropDown />
                                        </Button>

                                        <Menu {...bindMenu(popupState)}>

                                            <MenuItem
                                                onClick={() => { setImportContactModalOpen(true) }}

                                            >
                                                <Backup
                                                    fontSize="small"
                                                    color="primary"
                                                    style={{
                                                        marginRight: 10,
                                                    }}
                                                />
                                                {i18n.t("contacts.menu.importToExcel")}

                                            </MenuItem>


                                            {/* {<MenuItem>
                                
                                            <CSVLink
                                                    className={classes.csvbtn}
                                                    separator=";"
                                                    filename={'contacts.csv'}
                                                    data={
                                                        contacts.map((contact) => ({
                                                            number: hideNum && user.profile === "user" ? contact.isGroup ? contact.number : formatSerializedId(contact.number).slice(0,-6)+"**-**"+ contact.number.slice(-2): contact.isGroup ? contact.number : formatSerializedId(contact.number),
                                                            firstName: contact.name.split(' ')[0],
                                                            lastname: String(contact.name).replace(contact.name.split(' ')[0],''),
                                                            tags: contact?.tags?.name
                                                        }))

                                                    }
                                                    
                                                    >
                                                
                                                <CloudDownload fontSize="small"
                                                    color="primary"
                                                    style={{
                                                        marginRight: 10,
                                                    
                                                        }}                                                
                                                />        
                                                Exportar Excel                                
                                        </CSVLink>
                                                
                                            </MenuItem> } */}
                                        </Menu>
                                    </React.Fragment>
                                )}
                            </PopupState>

                        </Grid>

                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: "capitalize",
                                    boxShadow: "none",
                                    borderRadius: 6,
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                                onClick={handleimportContact}
                            >
                                {i18n.t("contacts.buttons.import")}
                            </Button>
                        </Grid>

                        <Grid item>

                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 6, paddingLeft: 40, paddingRight: 40, color: "white", fontWeight: "bold" }}
                                onClick={handleOpenContactModal}
                            >
                                {i18n.t("contacts.buttons.add")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            {importContactModalOpen && (
                <ContactImportWpModal
                    isOpen={importContactModalOpen}
                    handleClose={() => setImportContactModalOpen(false)}
                    selectedTags={selectedTags}
                    hideNum={hideNum}
                    userProfile={user.profile}
                />
            )}


            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <>
                    <input
                        style={{ display: "none" }}
                        id="upload"
                        name="file"
                        type="file"
                        accept=".xls,.xlsx"
                        onChange={() => {
                            setConfirmOpen(true);
                        }}
                        ref={fileUploadRef}
                    />
                </>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell>
                                    {i18n.t("contacts.table.name")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.whatsapp")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.email")}
                                </TableCell>
                                {/* <TableCell align="center">
                                    {i18n.t("contacts.table.lastMessage")}
                                </TableCell> */}
                                <TableCell align="center">
                                    {i18n.t("contacts.table.whatsapp")}
                                </TableCell>
                                <TableCell align="center">{"Status"}</TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.actions")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {stableSort(contacts, getComparator("asc", "name"))
                                    .slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
                                    .map((contact) => (
                                        <TableRow key={contact.id}>
                                            <TableCell style={{ paddingRight: 0 }}>
                                                {<Avatar src={`${contact?.urlPicture}`} />}
                                            </TableCell>
                                            <TableCell>{contact.name}</TableCell>
                                            <TableCell align="center">
                                                {((enableLGPD && hideNum && user.profile === "user")
                                                    ? contact.isGroup
                                                        ? contact.number :
                                                        formatSerializedId(contact?.number) === null ? contact.number.slice(0, -6) + "**-**" + contact?.number.slice(-2) :
                                                            formatSerializedId(contact?.number)?.slice(0, -6) + "**-**" + contact?.number?.slice(-2) :
                                                    contact.isGroup ? contact.number : formatSerializedId(contact?.number)
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {contact.email}
                                            </TableCell>
                                            {/* <TableCell align="center">
                                            {getDateLastMessage(contact)}
                                        </TableCell> */}
                                            <TableCell>{contact?.whatsapp?.name}</TableCell>
                                            <TableCell align="center">
                                                {contact.active ? (
                                                    <CheckCircleIcon
                                                        style={{ color: "green" }}
                                                        fontSize="small"
                                                    />
                                                ) : (
                                                    <CancelIcon
                                                        style={{ color: "red" }}
                                                        fontSize="small"
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    size="small"
                                                    disabled={!contact.active}
                                                    onClick={() => {
                                                        setContactTicket(contact);
                                                        setNewTicketModalOpen(true);
                                                        // handleSaveTicket(contact.id);
                                                    }}
                                                >
                                                    {contact.channel === "whatsapp" && (<WhatsApp style={{ color: "green" }} />)}
                                                    {contact.channel === "instagram" && (<Instagram style={{ color: "purple" }} />)}
                                                    {contact.channel === "facebook" && (<Facebook style={{ color: "blue" }} />)}
                                                </IconButton>

                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        hadleEditContact(contact.id)
                                                    }
                                                >
                                                    <EditIcon color="secondary" />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={
                                                        contact.active
                                                            ? () => {
                                                                setConfirmOpen(true);
                                                                setBlockingContact(
                                                                    contact
                                                                );
                                                            }
                                                            : () => {
                                                                setConfirmOpen(true);
                                                                setUnBlockingContact(
                                                                    contact
                                                                );
                                                            }
                                                    }
                                                >
                                                    {contact.active ? (
                                                        <BlockIcon color="secondary" />
                                                    ) : (
                                                        <CheckCircleIcon color="secondary" />
                                                    )}
                                                </IconButton>
                                                <Can
                                                    role={user.profile}
                                                    perform="contacts-page:deleteContact"
                                                    yes={() => (
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                setConfirmOpen(true);
                                                                setDeletingContact(
                                                                    contact
                                                                );
                                                            }}
                                                        >
                                                            <DeleteOutlineIcon color="secondary" />
                                                        </IconButton>
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {loading && <TableRowSkeleton avatar columns={6} />}
                            </>
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Paginación */}
                <TablePagination
                    rowsPerPageOptions={[10, 30, 50]}
                    component="div"
                    // count={contacts.length}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </MainContainer>
    );
};

export default Contacts;
