import React, { useState, useEffect, useRef } from "react";
import { parseISO, format } from "date-fns";
import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { TagsContainer } from "../TagsContainer";
// import AsyncSelect from "../AsyncSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	// textField: {
	// 	marginRight: theme.spacing(1),
	// 	flex: 1,
	// },

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(250, "Too Long!")
		.required("Required"),
	number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);

	const initialState = {
		name: "",
		number: "",
		email: "",
		disableBot: false,
		lgpdAcceptedAt: ""
	};

	const [contact, setContact] = useState(initialState);
	const [disableBot, setDisableBot] = useState(false);
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				if (isMounted.current) {
					setContact(data);
					setDisableBot(data.disableBot)
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleSaveContact = async values => {
		try {
			if (contactId) {
				await api.put(`/contacts/${contactId}`, { ...values, disableBot: disableBot });
				handleClose();
			} else {
				const { data } = await api.post("/contacts", { ...values, disableBot: disableBot });
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="xl" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting, setFieldValue }) => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("contactModal.form.mainInfo")}
								</Typography>



								<Grid container spacing={2}>
									<Grid item xs={12} md={3} lg={3}>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.name")}
											name="name"
											autoFocus
											fullWidth
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
									</Grid>

									<Grid item xs={12} md={3} lg={3}>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.number")}
											name="number"
											fullWidth
											error={touched.number && Boolean(errors.number)}
											helperText={touched.number && errors.number}
											placeholder="5513912344321"
											variant="outlined"
											margin="dense"
										/>
									</Grid>

									<Grid item xs={12} md={3} lg={3}>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.email")}
											name="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											placeholder="Correo electrónico"
											fullWidth
											margin="dense"
											variant="outlined"
										/>
									</Grid>

									<Grid item xs={12} md={3} lg={3}>
										<TagsContainer fullWidth contact={contact} className={classes.textField} />
									</Grid>





									<Grid item xs={12}>


										<Typography
											style={{ marginBottom: 8, marginTop: 12 }}
											variant="subtitle1"
										>
											<Switch
												size="small"
												checked={disableBot}
												onChange={() =>
													setDisableBot(!disableBot)
												}
												name="disableBot"
											/>
											{i18n.t("contactModal.form.chatBotContact")}
										</Typography>


										<Typography
											style={{ marginBottom: 8, marginTop: 12 }}
											variant="subtitle1"
										>
											{i18n.t("contactModal.form.whatsapp")} {contact?.whatsapp ? contact?.whatsapp.name : ""}
										</Typography>

										<Typography
											style={{ marginBottom: 8, marginTop: 12 }}
											variant="subtitle1"
										>
											{i18n.t("contactModal.form.termsLGDP")} {contact?.lgpdAcceptedAt ? format(new Date(contact?.lgpdAcceptedAt), "dd/MM/yyyy 'às' HH:mm") : ""}
										</Typography>

									</Grid>


								</Grid>











								{/* <Typography variant="subtitle1" gutterBottom>{i18n.t("contactModal.form.customer_portfolio")}</Typography> */}
								{/* 
								<div style={{ marginTop: 10 }}>
									<AsyncSelect url="/users" dictKey={"users"}
										initialValue={values.user} width="100%" label={i18n.t("contactModal.form.attendant")}
										onChange={(event, value) => setFieldValue("userId", value ? value.id : null)} />
								</div>
								<div style={{ marginTop: 10 }}>
									<AsyncSelect url="/queue" dictKey={null}
										initialValue={values.queue} width="100%" label={i18n.t("contactModal.form.queue")}
										onChange={(event, value) => setFieldValue("queueId", value ? value.id : null)} />
								</div> 
								*/}

								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{i18n.t("contactModal.form.extraInfo")}
								</Typography>






								<DialogActions>




									<Button
										onClick={handleClose}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
										style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 6, paddingLeft: 40, paddingRight: 40, fontWeight: "bold" }}
									>
										{i18n.t("contactModal.buttons.cancel")}
									</Button>


									<FieldArray name="extraInfo">
										{({ push, remove }) => (
											<>
												{values.extraInfo &&
													values.extraInfo.length > 0 &&
													values.extraInfo.map((info, index) => (
														<div
															className={classes.extraAttr}
															key={`${index}-info`}
														>

															<Grid container spacing={2}>
																<Grid item xs={12} md={6} lg={6}>
																	<Field
																		as={TextField}
																		label={i18n.t("contactModal.form.extraName")}
																		name={`extraInfo[${index}].name`}
																		variant="outlined"
																		margin="dense"
																		className={classes.textField}
																	/>
																</Grid>

																<Grid item xs={12} md={6} lg={6}>
																	<Field
																		as={TextField}
																		label={i18n.t("contactModal.form.extraValue")}
																		name={`extraInfo[${index}].value`}
																		variant="outlined"
																		margin="dense"
																		className={classes.textField}
																	/>
																</Grid>
															</Grid>
															<IconButton
																size="small"
																onClick={() => remove(index)}
															>
																<DeleteOutlineIcon />
															</IconButton>
														</div>
													))}
												<div className={classes.extraAttr}>
													<Button
														style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 6, paddingLeft: 40, paddingRight: 40, color: "white", fontWeight: "bold" }}
														variant="contained"
														color="primary"

														onClick={() => push({ name: "", value: "" })}
													>
														{`${i18n.t("contactModal.buttons.addExtraInfo")}`}
													</Button>
												</div>
											</>
										)}
									</FieldArray>



									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										// className={classes.btnWrapper}
										style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 6, paddingLeft: 40, paddingRight: 40, color: "white", fontWeight: "bold" }}
									>
										{contactId
											? `${i18n.t("contactModal.buttons.okEdit")}`
											: `${i18n.t("contactModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>





								</DialogActions>

							</DialogContent>


						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactModal;
