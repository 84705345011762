import React, { useState, useEffect } from "react";
import qs from 'query-string';

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";

import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
import Button from '@mui/material/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import usePlans from '../../hooks/usePlans';
import { i18n } from "../../translate/i18n";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";

// Define estilos reutilizando clases de `Login`
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/orbiplus-478ae.appspot.com/o/chateam_bg_do_not_delete%2F0-02-09-ae1b19e4567353a1aad611519ed2d7a05252502141c654063d8606992884c551_1c6dbb78a65ee63.gif?alt=media&token=d6acfe12-cea5-4cb9-af86-b783a6a5a1c7)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    },
    paper: {
        backgroundColor: '#ffffff',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "55px 30px",
        borderRadius: "12.5px",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    submit: {
        borderRadius: 0,
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#1e7eba",
        // color: "white",
        boxShadow: "none",
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: "none",
    }
}));

const UserSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Obrigatório"),
    companyName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Obrigatório"),
    password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
    email: Yup.string().email("Email inválido").required("Obrigatório"),
    phone: Yup.string().required("Obrigatório"),
});

const SignUp = () => {
    const classes = useStyles();
    const history = useHistory();
    const { getPlanList } = usePlans();
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);

    let companyId = null;
    const params = qs.parse(window.location.search);
    if (params.companyId !== undefined) {
        companyId = params.companyId;
    }

    const initialState = { name: "", email: "", password: "", phone: "", companyId, companyName: "", planId: "" };
    const [user] = useState(initialState);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const planList = await getPlanList({ listPublic: "false" });
            setPlans(planList);
            setLoading(false);
        };
        fetchData();
    }, [getPlanList]);

    const handleSignUp = async (values) => {
        try {
            await openApi.post("/auth/signup", values);
            toast.success(i18n.t("signup.toasts.success"));
            history.push("/login");
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div className={classes.root}>
            <Container component="main" >
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {i18n.t("signup.title")}
                    </Typography>
                    <Formik
                        initialValues={user}
                        enableReinitialize={true}
                        validationSchema={UserSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSignUp(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ touched, errors, isSubmitting }) => (
                            <Form className={classes.form}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            id="companyName"
                                            label={i18n.t("signup.form.company")}
                                            error={touched.companyName && Boolean(errors.companyName)}
                                            helperText={touched.companyName && errors.companyName}
                                            name="companyName"
                                            autoComplete="companyName"
                                            autoFocus
                                            style={{ 
                                                backgroundColor: "lightblue",
                                                color: "#000000", 
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            as={TextField}
                                            autoComplete="name"
                                            name="name"
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                            variant="outlined"
                                            fullWidth
                                            id="name"
                                            label={i18n.t("signup.form.name")}
                                            style={{ 
                                                backgroundColor: "lightblue",
                                                color: "#000000", 
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            id="email"
                                            label={i18n.t("signup.form.email")}
                                            name="email"
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                            autoComplete="email"
                                            style={{ 
                                                backgroundColor: "lightblue",
                                                color: "#000000", 
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="password"
                                            error={touched.password && Boolean(errors.password)}
                                            helperText={touched.password && errors.password}
                                            label={i18n.t("signup.form.password")}
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            style={{ 
                                                backgroundColor: "lightblue",
                                                color: "#000000", 
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            id="phone"
                                            label={i18n.t("signup.form.phone")}
                                            name="phone"
                                            autoComplete="phone"
                                            style={{ 
                                                backgroundColor: "lightblue",
                                                color: "#000000", 
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        {/* <InputLabel htmlFor="plan-selection">Planes</InputLabel> */}
                                        <Field
                                            as={Select}
                                            variant="outlined"
                                            fullWidth
                                            id="plan-selection"
                                            label="Plano"
                                            name="planId"
                                            style={{ 
                                                backgroundColor: "lightblue",
                                                color: "#000000", 
                                            }}
                                            required
                                        >
                                            {plans.map((plan, key) => (
                                                <MenuItem key={key} value={plan.id}>
                                                    {plan.name} - Encargados: {plan.users} - WhatsApp: {plan.connections} - Colas: {plan.queues} - $ {plan.amount}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                </Grid>



                                <Grid container justifyContent="space-between" spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    
                                    <Grid item>
                                        <Link
                                            // variant="body2"
                                            component={RouterLink}
                                            fullWidth
                                            to="/login"
                                            // className={classes.link}
                                        >
                                            <Button 
                                                variant="text" 
                                                style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 10, paddingLeft: 40, paddingRight: 40 }}>
                                                {i18n.t("signup.buttons.login")}
                                            </Button>
                                            
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className={classes.submit}
                                            style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 10, paddingLeft: 40, paddingRight: 40  }}
                                        >
                                            {i18n.t("signup.buttons.submit")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
                <Box mt={5} />
            </Container>
        </div>
    );
};

export default SignUp;
