import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// import Button from "@material-ui/core/Button";
import Button from '@mui/material/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import ColorModeContext from "../../layout/themeContext";
import useSettings from "../../hooks/useSettings";
import IconButton from "@material-ui/core/IconButton";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import { Checkbox, FormControlLabel } from '@mui/material';
import { Helmet } from "react-helmet";
import shadows from "@material-ui/core/styles/shadows";

const Copyright = () => {
	return (
		<Typography variant="body2" color="#fff" align="center">
			{"Copyright "}
			<Link color="#fff" href="#">
				Chateam - CodigoPlus
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100vw",
		height: "100vh",
		backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/orbiplus-478ae.appspot.com/o/chateam_bg_do_not_delete%2F0-02-09-ae1b19e4567353a1aad611519ed2d7a05252502141c654063d8606992884c551_1c6dbb78a65ee63.gif?alt=media&token=d6acfe12-cea5-4cb9-af86-b783a6a5a1c7)",
		backgroundRepeat: "no-repeat",
		backgroundSize: "100% 100%",
		backgroundPosition: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
	},
	paper: {
		backgroundColor: '#ffffff',
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "55px 30px",
		borderRadius: "12.5px",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(1),
	},
	submit: {
		borderRadius: 0,
		margin: 1,
		boxShadow: "none",
		backgroundColor: '#75bfe6',
		color: 'white',
		fontSize: '12px',
	},
	powered: {
		color: "white",
	},
	logoImg: {
		width: "100%",
		maxWidth: "350px",
		height: "auto",
		maxHeight: "120px",
		margin: "0 auto",
		content: "url(" + (theme.mode === "light" ? theme.calculatedLogoLight() : theme.calculatedLogoDark()) + ")",
	},
	iconButton: {
		position: "absolute",
		top: 10,
		right: 10,
		color: theme.mode === "light" ? "black" : "white",
	},
}));

const Login = () => {
	const classes = useStyles();
	const { colorMode } = useContext(ColorModeContext);
	const { appLogoFavicon, appName, mode } = colorMode;
	const [user, setUser] = useState({
		email: '',
		password: '',
		rememberMe: false,
	});
	const [allowSignup, setAllowSignup] = useState(false);
	const { getPublicSetting } = useSettings();
	const { handleLogin } = useContext(AuthContext);

	const handleChangeInput = (name, value) => {
		setUser({ ...user, [name]: value });
	};

	const handlSubmit = (e) => {
		e.preventDefault();
		handleLogin(user);
	};

	useEffect(() => {
		getPublicSetting("allowSignup")
			.then((data) => {
				setAllowSignup(data === "enabled");
			})
			.catch((error) => {
				console.log("Ajuste de lectura de errores", error);
			});
	}, []);

	return (
		<>
			<Helmet>
				<title>{appName || "Chateam"}</title>
				<link rel="icon" href={appLogoFavicon || "/default-favicon.ico"} />
			</Helmet>
			<div className={classes.root}>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
						<IconButton className={classes.iconButton} onClick={colorMode.toggleColorMode}>
							{mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
						</IconButton>
						<div>
							<img className={classes.logoImg} alt="logo" />
						</div>
						<form className={classes.form} noValidate onSubmit={handlSubmit}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label={i18n.t("login.form.email")}
								name="email"
								value={user.email}
								onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
								autoComplete="email"
								autoFocus
								InputProps={{
									style: {
										backgroundColor: mode === "dark" ? "lightblue" : "white",
										color: mode === "dark" ? "#000000" : "#000000",
									},
								}}
							/>

							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label={i18n.t("login.form.password")}
								type="password"
								id="password"
								value={user.password}
								onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
								autoComplete="current-password"
								InputProps={{
									style: {
										backgroundColor: mode === "dark" ? "lightblue" : "white",
										color: mode === "dark" ? "#000000" : "#000000",
									},
								}}
							/>

							<Grid container marginTop={2} marginBottom={2} justifyContent="flex-end">
								<Grid item>
									<Link
										component={RouterLink}
										to="/forgetpsw"
										variant="body2"
										style={{
											color: mode === "dark" ? "#000000" : "#000000",
											textDecoration: "none",
										}}
									>
										¿Ha olvidado su contraseña?
									</Link>
								</Grid>
							</Grid>

							<Grid container justifyContent="space-between" spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
								<Grid item xs={6}>
									<Button
										fullWidth
										variant="text"
										component={RouterLink}
										to="/signup"
										style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 6, paddingLeft: 40, paddingRight: 40, fontWeight: "bold" }}
										
									>
										{i18n.t("login.buttons.register")}
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										// className={classes.submit}
										style={{ textTransform: "capitalize", boxShadow: "none", borderRadius: 6, paddingLeft: 40, paddingRight: 40, color: "white", fontWeight: "bold" }}
									>
										{i18n.t("login.buttons.submit")}
									</Button>
								</Grid>
							</Grid>

							<FormControlLabel
								control={
									<Checkbox
										checked={user.rememberMe}
										onChange={(e) => handleChangeInput('rememberMe', e.target.checked)}
										color="primary"
									/>
								}
								label="Recuerdame"
								style={{
									color: mode === "dark" ? "#000000" : "#000000",
									textDecoration: "none",
								}}
							/>

							{allowSignup && (
								<Grid container>
									<Grid item>
										<Link href="#" variant="body2" component={RouterLink} to="/signup">
											{i18n.t("login.buttons.register")}
										</Link>
									</Grid>
								</Grid>
							)}
						</form>
					</div>
					<Box> Copyright 2024 - Chateam - CodigoPlus </Box>
				</Container>
			</div>
		</>
	);
};

export default Login;
